import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Services
import LocalStorageService from '../services/LocalStorageService'
import ReportService from '../services/ReportService'
import NotificationService from '../services/NotificationService'

// Utils
import { content } from '../utils/content'
import { constants } from '../utils/constants'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import CounterBox from '../components/compound/counterBox/CounterBox'
import Divider from '../components/simple/divider/Divider'
import Form from '../components/simple/form/Form.js'
import H1 from '../components/simple/heading/H1'
import H3 from '../components/simple/heading/H3'
import Text from '../components/simple/text/Text'
import Select from '../components/simple/input/Select'
import CriteriaBox from '../screens/reports/shared/CriteriaBox'
import TypeBox from './reports/shared/TypeBox'
import { isCurrentOrgAdmin, hasPermission } from '../utils/permissions'

// dashboard components
import Grid from '../components/simple/grid/Grid'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'
import DateInput from '../components/simple/input/DateInput'

import FormField from '../components/simple/formField/FormField.js'

// chart.js
import { PolarArea, Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js'

function Reports (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // Count of number of rows of queries
  const [criteriaIndex, setCriteriaIndex] = useState(0)
  const [fields, setFields] = useState([])
  const [modelType, setModelType] = useState()
  const [newQuery, setNewQuery] = useState({})
  const [isExport, setIsExport] = useState(true)

  const [fromDate, setFromDate] = useState('2019-01-01')
  const [toDate, setToDate] = useState('2023-01-01')
  const [dateRange, setDateRange] = useState('custom')
  const dateFormat = 'YYYY-MM-DD'

  useEffect(() => {
    console.log("trace newQuery", newQuery)
  }, [newQuery])

  useEffect(() => {
    setDateRange('thismonth')
  }, [])

  const setDatesFromRange = (range) => {
    if (range === 'custom') return
    var fromDate = moment().startOf('year').format(dateFormat)
    var toDate = moment().endOf('day').format(dateFormat)

    switch (range) {
      case 'today':
        fromDate = moment().startOf('day').format(dateFormat)
        break
      case 'yesterday':
        fromDate = moment().startOf('day').subtract(1, 'day').format(dateFormat)
        toDate = moment().endOf('day').subtract(1, 'day').format(dateFormat)
        break
      case 'thisweek':
        fromDate = moment().startOf('week').format(dateFormat)
        break
      case 'lastweek':
        fromDate = moment().startOf('week').subtract(7, 'day').format(dateFormat)
        toDate = moment().endOf('week').subtract(7, 'day').format(dateFormat)
        break
      case 'thismonth':
        fromDate = moment().startOf('month').format(dateFormat)
        break
      case 'lastmonth':
        fromDate = moment().startOf('month').subtract(1, 'month').format(dateFormat)
        toDate = moment().endOf('month').subtract(1, 'month').format(dateFormat)
        break
      case 'thisyear':
        fromDate = moment().startOf('year').format(dateFormat)
        break
      case 'lastyear':
        fromDate = moment().startOf('year').subtract(1, 'year').format(dateFormat)
        toDate = moment().endOf('year').subtract(1, 'year').format(dateFormat)
        break
      case 'alltime':
        fromDate = moment().startOf('year').subtract(100, 'year').format(dateFormat)
        toDate = moment().endOf('year').add(100, 'year').format(dateFormat)
        break
    }
    setToDate(toDate)
    setFromDate(fromDate)
  }

  useEffect(() => {
    setDatesFromRange(dateRange)
  }, [dateRange])

  // Array of field types for the currently
  // selected Field box
  const [query, setQuery] = useState()

  const [kpis, setKpis] = useState({})

  const [searchCount, setSearchCount] = useState(0)

  // check permission
  useEffect(() => {
    if (!isCurrentOrgAdmin() && !hasPermission('reportAccessModule')) {
      history.push('/dashboard')
    }
  }, [])

  const getKPIs = async () => {
    const response = await ReportService.userReports(apiToken, fromDate, toDate)
    if (response?.data) {

      var dataToSave = response.data

      var labels = []
      var data = []

      // process gender
      if (dataToSave?.inServices?.genders?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.genders) {
          labels.push(row.gender || 'n/a')
          data.push(row.count)
        }
        dataToSave.genderLabelsInService = labels
        dataToSave.genderDataInService = data
      }

      // process exploitation
      if (dataToSave?.inServices?.exploitation?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.exploitation) {
          labels.push(row.exploitationType || 'n/a')
          data.push(row.count)
        }
        dataToSave.exploitationLabelsInService = labels
        dataToSave.exploitationDataInService = data
      }

      // process country
      if (dataToSave?.inServices?.country?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.country) {
          labels.push(row.country || 'n/a')
          data.push(row.count)
        }
        dataToSave.countryLabelsInService = labels
        dataToSave.countryDataInService = data
      }

      // process numChildren
      if (dataToSave?.inServices?.numChildren?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.numChildren) {
          labels.push(row.numChildren || 'n/a')
          data.push(row.count)
        }
        dataToSave.numChildrenLabelsInService = labels
        dataToSave.numChildrenDataInService = data
      }

      // process numRenovations
      if (dataToSave?.inServices?.numRenovations?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.numRenovations) {
          labels.push(row.numRenovations || 'n/a')
          data.push(row.count)
        }
        dataToSave.numRenovationsLabelsInService = labels
        dataToSave.numRenovationsDataInService = data
      }

      // process clientsByArea
      if (dataToSave?.inServices?.clientsByArea?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.clientsByArea) {
          labels.push(row.city || 'n/a')
          data.push(row.count)
        }
        dataToSave.clientsByAreaLabelsInService = labels
        dataToSave.clientsByAreaDataInService = data
      }

      // process riskLevel
      if (dataToSave?.inServices?.riskLevel?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.riskLevel) {
          labels.push(row.riskLevel || 'n/a')
          data.push(row.count)
        }
        dataToSave.riskLevelLabelsInService = labels
        dataToSave.riskLevelDataInService = data
      }

      // process startingImmigration
      if (dataToSave?.inServices?.startingImmigration?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.startingImmigration) {
          labels.push(row.status || 'n/a')
          data.push(row.count)
        }
        dataToSave.startingImmigrationLabelsInService = labels
        dataToSave.startingImmigrationDataInService = data
      }

      // process endingImmigration
      if (dataToSave?.inServices?.endingImmigration?.length) {
        labels = []
        data = []
        for (const row of dataToSave.inServices.endingImmigration) {
          labels.push(row.status || 'n/a')
          data.push(row.count)
        }
        dataToSave.endingImmigrationLabelsInService = labels
        dataToSave.endingImmigrationDataInService = data
      }

      // process gender
      if (dataToSave?.exitingServices?.genders?.length) {
        labels = []
        data = []
        for (const row of dataToSave.exitingServices.genders) {
          labels.push(row.gender || 'n/a')
          data.push(row.count)
        }
        dataToSave.genderLabels = labels
        dataToSave.genderData = data
      }

      // process exploitation
      if (dataToSave?.exitingServices?.exploitation?.length) {
        labels = []
        data = []
        for (const row of dataToSave.exitingServices.exploitation) {
          labels.push(row.exploitationType || 'n/a')
          data.push(row.count)
        }
        dataToSave.exploitationLabels = labels
        dataToSave.exploitationData = data
      }

      // process country
      if (dataToSave?.exitingServices?.country?.length) {
        labels = []
        data = []
        for (const row of dataToSave.exitingServices.country) {
          labels.push(row.country || 'n/a')
          data.push(row.count)
        }
        dataToSave.countryLabels = labels
        dataToSave.countryData = data
      }

      // process startingImmigration
      if (dataToSave?.exitingServices?.startingImmigration?.length) {
        labels = []
        data = []
        for (const row of dataToSave.exitingServices.startingImmigration) {
          labels.push(row.status || 'n/a')
          data.push(row.count)
        }
        dataToSave.startingImmigrationLabels = labels
        dataToSave.startingImmigrationData = data
      }

      // process endingImmigration
      if (dataToSave?.exitingServices?.endingImmigration?.length) {
        labels = []
        data = []
        for (const row of dataToSave.exitingServices.endingImmigration) {
          labels.push(row.status || 'n/a')
          data.push(row.count)
        }
        dataToSave.endingImmigrationLabels = labels
        dataToSave.endingImmigrationData = data
      }

      console.log('kpis', dataToSave)
      setKpis(dataToSave)
    }
  }
  useEffect(() => {
    getKPIs()
  }, [fromDate, toDate])

  // Build the whole form by adding one
  // query row for each join option added
  const buildCriteria = () => {
    const criteriaTable = []

    for (let i = 0; i <= criteriaIndex; i++) {
      criteriaTable.push(<CriteriaBox index={i} addJoin={criteriaIndex > 0 && i !== criteriaIndex} fields={fields} removeCriteria={removeCriteria} setFields={setFields} modelType={modelType} />)
    }
    return criteriaTable
  }

  const removeQuery = (index) => {
    if (query && query[`Field-${index}`]) {
      delete query[`Field-${index}`]
    }

    if (query && query[`Query-${index}`]) {
      delete query[`Query-${index}`]
    }

    if (query && query[`Value-${index}`]) {
      delete query[`Value-${index}`]
    }

    if (query && query[`Join-${index}`]) {
      delete query[`Join-${index}`]
    }
  }

  // Delete a Criteria row
  const removeCriteria = (index) => {
    removeQuery(index)

    const newQuery = {}

    for (const item in query) {
      const fieldName = item.split('-')[0]
      let fieldIndex = item.split('-')[1]
      const fieldValue = query[fieldName.concat('-', fieldIndex)]

      if (parseInt(fieldIndex) > index) {
        fieldIndex--
      }

      newQuery[fieldName.concat('-', fieldIndex)] = fieldValue
    }

    updateValues(index, newQuery)
  }

  const updateValues = (index, newQuery) => {
    const newFields = fields

    newFields.splice(index, 1)
    setFields(newFields)

    setQuery(newQuery)
    setCriteriaIndex(criteriaIndex - 1)
    buildCriteria()
  }

  // Add another row to the query
  const addCriteria = () => {
    // default the Join to AND
    if (query && query[`Field-${criteriaIndex}`]) {
      query[`Join-${criteriaIndex}`] = 'AND'
    }

    setCriteriaIndex(criteriaIndex + 1)
  }

  const goBack = () => { }

  const exportReport = (data) => {
    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'report.csv'
    hiddenElement.click()
  }

  const submitTask = async () => {
    var where
    if (Object.keys(newQuery).length !== 0) {
      where = getWhere()
    }
    getReports(where)
  }

  const getWhere = () => {
    const where = {}
    const orArray = []

    for (var i = 0; i <= criteriaIndex; i++) {
      // override this for now
      where[newQuery['Field-' + i].value] = newQuery['Query-' + i] === '=' ? newQuery['Value-' + i] : { [newQuery['Query-' + i]]: newQuery['Value-' + i] }
    }
    return where
  }

  const getReports = async (where) => {
    const params = {
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      object: modelType
    }

    if (isExport) {
      await ReportService.exportReports(apiToken, params, where, !isExport)
    } else {
      const reports = await ReportService.getReports(apiToken, params, where, !isExport)
      console.log('reports ', reports)
      if (reports?.data?.count) {
        setSearchCount(reports.data.count)
      }
    }
  }
  ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

  return (
    <>
      {/* Title and breadcrumbs */}
      <Box background='white' border={{ color: 'panelBorder', size: 'small' }} round='xsmall' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }}>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>{content.reports.title}</H1>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>{content.reports.location_main_path}</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>
      {/* export data box */}
      <Box background='white' border={{ color: 'panelBorder', size: 'small' }} round='xsmall' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }}>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <H3 margin={{ top: 'xsmall' }}>{content.reports.type_label}</H3>
            </Box>
            <TypeBox setModelType={setModelType} />
          </Box>
        </Box>
      </Box>

      {/* Main */}
      {modelType && <Box background='white' direction='column' gap='small' border={{ color: 'panelBorder', size: 'small' }} round='xsmall' elevation='small' pad='none' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <Text margin={{ top: 'xsmall' }} size='large'>{content.reports.filter.title}</Text>
            </Box>

            <Form
              onChange={nextValue => { setNewQuery(nextValue) }}
              onSubmit={({ value: nextValue }) => { submitTask(nextValue) }}
              value={query}
            >
              <Box direction='column' gap='xsmall' margin={{ bottom: 'small' }}>
                {buildCriteria()}
                <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> {content.reports.filter.add_criteria_button_label}</Text>} color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button type='submit' label={content.reports.filter.export_button_label} primary />
              </Box>
              {searchCount > 0 && <Text>There are {searchCount} results to display</Text>}
            </Form>
          </Box>
        </Box>
      </Box>}

      {modelType && <Divider color='primary' margin={{ vertical: 'medium' }} /> }

      {/* stats dashboard  */}
      <Box background='white' border={{ color: 'panelBorder', size: 'small' }} round='xsmall' elevation='small' direction='column' gap='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <H3 margin={{ top: 'xsmall' }}>KPIs</H3>
            </Box>
            <FormField
              direction='column'
              label='Type'
              name='modelType'
            >
              <Select
                name='dateRange'
                labelKey='label'
                onChange={({ value: nextValue }) => {
                  setDateRange(nextValue)
                }}
                value={dateRange}
                options={constants.dateRanges}
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
            {/* Date */}
            <FormField
              label='From'
              name='fromDate'
            >
              {dateRange !== 'custom' && <Text>{moment(fromDate, dateFormat).format('DD/MM/YYYY')}</Text>}
              {dateRange === 'custom' && <DateInput
                format='dd/mm/yyyy'
                value={moment(fromDate, dateFormat).toISOString()}
                onChange={({ value: nextValue }) => {
                  setFromDate(moment(nextValue).format(dateFormat))
                  setDateRange('custom')
                }}
              />}
            </FormField>
            <FormField
              label='To'
              name='toDate'
            >
              {dateRange !== 'custom' && <Text>{moment(toDate, dateFormat).format('DD/MM/YYYY')}</Text>}
              {dateRange === 'custom' && <DateInput
                format='dd/mm/yyyy'
                value={moment(toDate, dateFormat).toISOString()}
                onChange={({ value: nextValue }) => {
                  setToDate(moment(nextValue).format(dateFormat))
                  setDateRange('custom')
                }}
              />}
            </FormField>
          </Box>
        </Box>
      </Box>

      {/* referrals containers */}
      <Text size='medium' weight='bold' margin={{ top: 'small' }}>Key Stats</Text>
      <Grid columns={{ count: 3, size: 'auto' }}>

        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'user']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.referredCount || 0} People</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Referred</Text>
          </Box>
        </Box>
        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'user']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.inServicesCount || 0} People</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>In Services</Text>
          </Box>
        </Box>
        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'user']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.exitingServices?.exitingCount || 0} People</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Exiting Services</Text>
          </Box>
        </Box>
        {/* <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'clipboard']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'> ? People</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Waiting for Services</Text>
          </Box>
        </Box> */}
      </Grid>

      <Grid columns={{ count: 4, size: 'auto' }}>
        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'clipboard']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.averageLengthOfSupport || 0} Days</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Avg. Length of Support</Text>
          </Box>
        </Box>
        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'clipboard']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.averageAge || 0} Years</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Avg. Age of All Clients</Text>
          </Box>
        </Box>
        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'clipboard']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.averageCounselling?.[0]?.avgDays || 0} Days</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Avg. Length of Counselling</Text>
          </Box>
        </Box>

        <Box
          background='white' elevation='small' margin='xsmall' pad='small' round='small' flex='true' direction='row'
          align='center'
        >
          <FontAwesome icon={['fal', 'clipboard']} size='2x' />
          <Box margin={{ left: 'small' }}>
            <Text size='medium' weight='bold'>{kpis?.inServices?.numCountries || 0} Nationalities</Text>
            <Text margin={{ bottom: 'small' }} size='xsmall'>Represented</Text>
          </Box>
        </Box>

      </Grid>


      <Text size='medium' weight='bold' margin={{ top: 'small' }}>Snapshot</Text>
      <Grid columns={{ count: 2, size: 'auto' }}>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Gender</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by gender</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.genderLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.genderLabelsInService,
                datasets: [{
                  label: 'Gender',
                  data: kpis.genderDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Exploitation</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by exploitation</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.exploitationLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.exploitationLabelsInService,
                datasets: [{
                  label: 'Explotation',
                  data: kpis.exploitationDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Country</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by country of origin</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.countryLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.countryLabelsInService,
                datasets: [{
                  label: 'Country',
                  data: kpis.countryDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>

        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Num Children</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by # of children</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.numChildrenLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.numChildrenLabelsInService,
                datasets: [{
                  label: '# Children',
                  data: kpis.numChildrenDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>

        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Num Renovations</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by # of renovations</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.numRenovationsLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.numRenovationsLabelsInService,
                datasets: [{
                  label: '# Children',
                  data: kpis.numRenovationsDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>

        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> By Area</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by area</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.clientsByAreaLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.clientsByAreaLabelsInService,
                datasets: [{
                  label: '# Children',
                  data: kpis.clientsByAreaDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>

        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> By Risk Level</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients in services by risk level</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.riskLevelLabelsInService?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.riskLevelLabelsInService,
                datasets: [{
                  label: '# Children',
                  data: kpis.riskLevelDataInService,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>


      </Grid>

      <Text size='medium' weight='bold' margin={{ top: 'small' }}>Outcomes</Text>
      <Grid columns={{ count: 2, size: 'auto' }}>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Gender</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients exiting services by gender</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.genderLabels?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.genderLabels,
                datasets: [{
                  label: 'Gender',
                  data: kpis.genderData,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Exploitation</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients exiting services by exploitation</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.exploitationLabels?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.exploitationLabels,
                datasets: [{
                  label: 'Explotation',
                  data: kpis.exploitationData,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Country</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients exiting services by country of origin</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.countryLabels?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.countryLabels,
                datasets: [{
                  label: 'Country',
                  data: kpis.countryData,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>
        <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
          <Text size='medium' weight='bold'> Status</Text>
          <Text margin={{ bottom: 'small' }} size='xsmall'>% of clients by legal status at the end of the period</Text>
          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
          {kpis?.endingImmigrationLabels?.length > 0 ?
            <PolarArea
              data={{
                labels: kpis.endingImmigrationLabels,
                datasets: [{
                  label: 'Country',
                  data: kpis.endingImmigrationData,
                  backgroundColor: constants.kpiColors,
                  borderColor: constants.kpiColors,
                  borderWidth: 1
                }]
              }}
            /> : <Text size='small'>No data to display</Text>}
        </Box>
      </Grid>
    </>
  )
}

export default Reports
